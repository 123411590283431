'use strict';

var appDom = require('../app-dom');

function addPrepClass() {
    const items = document.querySelectorAll('.block__animate-in');

    items.forEach(function(entry) {
        if(entry.classList) {
            entry.classList.add("animate-hide");
        }
    });
    
}

function blockScrollObserver() {
    const items = document.querySelectorAll('.block__animate-in');

    const observer = new IntersectionObserver(function(entries) {
       
        entries.forEach(function(entry) {
            if (entry.boundingClientRect.top < 0 && entry.isIntersecting === false) {
                entry.target.classList.add("animate-show");
            }

            if (entry.isIntersecting) {

                entry.target.classList.add("animate-show");

                observer.unobserve(entry.target);

            }
        });

    }, { threshold: 0 });

    items.forEach(function(block) {
        observer.observe(block);
    });
}

function addEventHandlers() {
    document.addEventListener("DOMContentLoaded", blockScrollObserver());
}

var init = function() {


    if (appDom.document.find('.block')) {
        
        addPrepClass();

    }

    if (appDom.document.find('.block-content')) {
        
        addEventHandlers();

    }

};

module.exports = {
    init: init
};